<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="권한"
          tableId="table"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :isExcelDown="false"
          :data="grid.data"
          :filtering="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getGrpList" />
            </q-btn-group>
          </template>
          <template slot="table-header-append">
            <c-select
              codeGroupCd="DEVICE_CD"
              codeAttrVal1="Y"
              type="none"
              itemText="codeName"
              itemValue="code"
              name="deviceTypeCd"
              label=""
              v-model="searchParam.deviceTypeCd"
              @input="getGrpList"
            ></c-select>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <c-table
          ref="tablemenu"
          title="권한별 메뉴목록"
          tableId="tablemenu"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :columns="gridmenu.columns"
          :data="gridmenu.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="메뉴추가/삭제" icon="add" @btnClicked="addMenu" v-if="isSelected && editable" />
              <c-btn label="권한저장" icon="save" @btnClicked="saveMenu" v-if="isSelected && editable" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog
      ref="menuDialog"
      persistent
      v-model="isMenuOpen">
      <q-card class="menu-card" style="width: 600px; height:800px">
        <q-card-section class="row items-center q-pb-none px-2 py-1 bg-orange-custom text-white">
          <div class="text-h6">메뉴</div>
          <q-space></q-space>
          <q-btn icon="done" flat round dense @click="selectMenu">
            <q-tooltip>
              메뉴추가 및 삭제
            </q-tooltip>
          </q-btn>
          <q-btn icon="close" flat round dense v-close-popup></q-btn>
        </q-card-section>
        <q-separator></q-separator>
        <q-card-section class="menu-content p-2">
          <q-tree
            ref="menuTree"
            :nodes="menuTree"
            node-key="sysMenuId"
            label-key="menuNm"
            children-key="children"
            :default-expand-all="false"
            selected-color="primary"
            tick-strategy="leaf"
            :ticked.sync="ticked"
            :editable="editable"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import arrayToTree from 'array-to-tree';
export default {
  name: 'auth-group-menu',
  data() {
    return {
      ticked: [],
      isMenuOpen: false,
      searchParam: {
        useFlag: 'Y',
        deviceTypeCd: 'P',
      },
      grid: {
        columns: [
          {
            name: 'sysAuthGrpName',
            field: 'sysAuthGrpName',
            label: '권한명',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      gridmenu: {
        columns: [
          {
            name: 'padMenuNm',
            field: 'padMenuNm',
            label: '메뉴명',
            align: 'left',
            type: 'html',
            style: 'width:75%',
            sortable: false,
          },
          {
            name: 'userWriteFlag',
            field: 'userWriteFlag',
            label: '쓰기권한',
            align: 'center',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N',
            value: 'N',
            setHeader: true,
            disableTarget: 'editableFlag',
            disableCon: false
          },
          // {
          //   name: 'deptWriteFlag',
          //   field: 'deptWriteFlag',
          //   label: '부서 쓰기권한',
          //   align: 'center',
          //   sortable: false,
          //   type: 'check',
          //   true: 'Y',
          //   false: 'N',
          //   value: 'N',
          //   setHeader: true,
          //   disableTarget: 'editableFlag',
          //   disableCon: false
          // }
        ],
        data: [],
      },
      menuTree: [],
      menulistUrl: '',
      listUrl: '',
      editable: true,
      isSelected: false,
      selectedSysAuthGrpId: '',
      addUrl: transactionConfig.sys.auth.menu.add.url,
      saveUrl: transactionConfig.sys.auth.menu.save.url,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.menulistUrl = selectConfig.sys.menu.list.url
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sys.auth.grp.list.url;
      this.detailUrl = selectConfig.sys.auth.menu.list.url;
      this.addUrl = transactionConfig.sys.auth.menu.add.url;
      this.saveUrl = transactionConfig.sys.auth.menu.save.url;
      this.getGrpList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getGrpList() {
      this.isSelected = false;
      this.getList();
      this.rowRemoveSelect();
      this.gridmenu.data = [];
      this.ticked = [];
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.$store.getters.user.userId === 'U000000001') {
          this.grid.data = _result.data;
        } else {
          this.grid.data = this.$_.filter(_result.data, item => {return item['sysAuthGrpId'] !== 'SAGI000000'});
        }
      },);
    },
    rowClick(row) {
      // 상세조회
      this.selectedSysAuthGrpId = row.sysAuthGrpId;
      this.$http.url = this.detailUrl;
      this.$http.param = {sysAuthGrpIds: this.selectedSysAuthGrpId}
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridmenu.data = _result.data;
        this.ticked = [];
        this.$_.forEach(_result.data, (_item) => {
          if (_item.sysAuthGrpId !== null) {
            this.ticked.push(_item.sysMenuId);
          }
        });
        this.isSelected = true;
      },
      () => {
      });
    },
    convertTree(_treeBases) {
      if (!_treeBases || _treeBases.length <= 0) return null;
      var tree = arrayToTree(_treeBases, {
        parentProperty: 'upMenuId',
        customID: 'sysMenuId',
      });
      return tree;
    },
    addMenu() {
      this.$http.url = this.menulistUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.menuTree = this.convertTree(_result.data);
        this.isMenuOpen = true;
      },);
    },
    saveMenu() {
      let saveData = this.gridmenu.data.filter( x => {
        return x.editFlag == 'U'
      });
      this.$http.url = this.saveUrl;
      this.$http.type = 'POST';
      this.$http.param = saveData;
      this.$http.request(() => {
        window.getApp.$emit('APP_REQUEST_SUCCESS');
        this.rowClick({sysAuthGrpId: this.selectedSysAuthGrpId});
      });
    },
    selectMenu() {
      let existData = [];
      this.$_.forEach(this.ticked, (_item) => {
        this.$_.forEach(this.gridmenu.data, (_itemMenu) => {
          if (_item == _itemMenu.sysMenuId) {
            existData.push(_item);
          }
        });
      });
      let saveData = this.ticked.filter(item => !existData.includes(item));

      this.$http.url = this.addUrl;
      this.$http.type = 'POST';
      this.$http.param = {
        sysAuthGrpId: this.selectedSysAuthGrpId,
        existMenu: existData,
        newMenu: saveData,
      };
      this.$http.request(() => {
        this.rowClick({sysAuthGrpId: this.selectedSysAuthGrpId});
        this.isMenuOpen = false;
        window.getApp.$emit('APP_REQUEST_SUCCESS');
      });
    },
  }
};
</script>
